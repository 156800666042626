import axios from 'axios';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from 'react-router';
import { useTheme } from "../../components/ThemeContext";
import { AuthAPIUrls } from "../../utils/APIUrls";
import { getCurrentButtonStyles, getCurrentInputStyles, getCurrentStyles } from "../../utils/Common";
import Url, { getWebsiteUrl } from "../../utils/Url";
import { validate400Errors, validateEmail, validatePassword, validatePasswordMatch } from '../../utils/validationUtils';

const ResetPassword = () => {
    const {token} = useParams<{ token: string }>();
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [isResetting, setIsResetting] = useState(false);
    const [isValidating, setIsValidating] = useState(true);
    const [isTokenValid, setIsTokenValid] = useState(false);
    const navigate = useNavigate();
    const {isDarkMode} = useTheme();

    useEffect(() => {
        const validateToken = async () => {
            try {
                const response = await axios.post(AuthAPIUrls.VERIFY_PASSWORD_REST_TOKEN, {
                    token: token,
                    url: getWebsiteUrl()
                });

                if (response.status === 200) {
                    setIsTokenValid(true);
                } else {
                    setError('Invalid or expired password reset link');
                }
            } catch (error: any) {
                setError('Invalid or expired password reset link');
                setTimeout(() => {
                    navigate('/404');
                }, 3000);
            } finally {
                setIsValidating(false);
            }
        };

        if (token) {
            validateToken().then(r => r);
        } else {
            setError('Invalid password reset link');
            setTimeout(() => {
                navigate('/404');
            }, 3000);
        }
    }, [token, navigate]);

    const handleResetPassword = async () => {
        const emailError = validateEmail(email);
        if (emailError) {
            setError(emailError);
            return;
        }

        const passwordError = validatePassword(newPassword);
        if (passwordError) {
            setError(passwordError);
            return;
        }

        const passwordMatchError = validatePasswordMatch(newPassword, confirmPassword);
        if (passwordMatchError) {
            setError(passwordMatchError);
            return;
        }

        setIsResetting(true);

        try {
            const response = await axios.post(AuthAPIUrls.FULFILL_PASSWORD_RESET, {
                email: email,
                token: token,
                newPassword: newPassword,
                url: getWebsiteUrl()
            });

            if (response.status === 200) {
                navigate(`/login`);
            }
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            setError(validate400Errors(error));
                            break;
                        case 401:
                            setError('Invalid or expired reset token. Please request a new password reset.');
                            break;
                        case 404:
                            setError('Email address not found. Please check your email and try again.');
                            break;
                        case 422:
                            setError('Invalid password format. Please ensure your password meets the requirements.');
                            break;
                        default:
                            setError('An error occurred during password reset. Please try again later.');
                    }
                } else if (error.request) {
                    setError('Unable to connect to the server. Please check your internet connection.');
                }
            } else {
                setError('An unexpected error occurred. Please try again later.');
            }
            console.error('Password reset error:', error);
        } finally {
            setIsResetting(false);
        }
    };

    if (isValidating) {
        return (
            <div
                className="flex flex-col items-center justify-center min-h-screen overflow-hidden text-white bg-blue-900">
                <motion.h1
                    initial={{opacity: 0, y: -20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.5}}
                    className="mb-8 text-4xl font-extrabold text-center"
                >
                    Please wait while we validate your request...
                </motion.h1>
            </div>
        );
    }

    if (!isTokenValid) {
        return null;
    }

    return <>
        <Helmet>
            <title>Revise Wizard - Reset Password</title>
            <meta name="description" content="Reset your Revise Wizard account password"/>
            <link rel="canonical" href={getWebsiteUrl() + Url.RESET_PASSWORD}/>
        </Helmet>
        <div className="flex flex-col items-center justify-center flex-grow py-6 overflow-hidden text-white bg-blue-900 md:py-8"
             style={getCurrentStyles(isDarkMode)}>
            <h1 className="mb-8 text-4xl font-extrabold text-center">Reset Password</h1>

            {error && (
                <div className="px-4 py-2 mb-4 text-center text-white bg-red-500 rounded w-96">
                    {error}
                </div>
            )}

            <div className="mb-4 w-96">
                <label className="block mb-2 text-lg">Email:</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-4 py-2 text-black rounded"
                    style={getCurrentInputStyles(isDarkMode)}
                />
            </div>

            <div className="mb-4 w-96">
                <label className="block mb-2 text-lg">New Password:</label>
                <input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="w-full px-4 py-2 text-black rounded"
                    style={getCurrentInputStyles(isDarkMode)}
                />
            </div>

            <div className="mb-4 w-96">
                <label className="block mb-2 text-lg">Confirm Password:</label>
                <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full px-4 py-2 text-black rounded"
                    style={getCurrentInputStyles(isDarkMode)}
                />
            </div>

            <button
                className="px-4 py-2 text-white transition duration-300 bg-green-500 rounded"
                onClick={handleResetPassword}
                disabled={isResetting}
                style={getCurrentButtonStyles(isDarkMode)}
            >
                {isResetting ? 'Resetting...' : 'Reset Password'}
            </button>
        </div>
    </>;
};

export default ResetPassword;
