import React from 'react';
import { Link } from 'react-router-dom';
import Url from "../utils/Url";
import { useTheme } from "./ThemeContext";

const Footer = () => {
    const { isDarkMode } = useTheme();

    return (
        <footer className={`py-6 w-full ${isDarkMode ? 'bg-gray-800 text-gray-400' : 'bg-gray-100 text-gray-700'}`}>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex flex-wrap justify-center gap-4 text-center">
                    <p className={`${isDarkMode ? 'text-gray-400' : 'text-gray-600'} font-medium`}>
                        &copy; 2024 Revise Wizard. All rights reserved.
                    </p>
                    <Link to={Url.USER_AGREEMENT}
                          className={`${isDarkMode ? 'hover:text-white' : 'hover:text-black'} cursor-pointer hover:underline`}>
                        User Agreement
                    </Link>
                    <Link to={Url.TERMS_OF_SERVICE}
                          className={`${isDarkMode ? 'hover:text-white' : 'hover:text-black'} cursor-pointer hover:underline`}>
                        Terms of Service
                    </Link>
                    <Link to={Url.PRIVACY_POLICY}
                          className={`${isDarkMode ? 'hover:text-white' : 'hover:text-black'} cursor-pointer hover:underline`}>
                        Privacy Policy
                    </Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer;