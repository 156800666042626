import HCaptcha from '@hcaptcha/react-hcaptcha';
import axios from 'axios';
import { motion } from 'framer-motion';
import { ChangeEvent, useState } from 'react';
import { Helmet } from "react-helmet-async";
import { useTheme } from "../../components/ThemeContext";
import { AuthAPIUrls } from "../../utils/APIUrls";
import { getCurrentButtonStyles, getCurrentInputStyles, getCurrentStyles } from '../../utils/Common';
import Url, { getWebsiteUrl } from '../../utils/Url';
import { validate400Errors, validateEmail, validateHCaptcha } from '../../utils/validationUtils';

const ResetPasswordRequest = () => {
    const [email, setEmail] = useState<string>('');
    const [hcaptchaToken, setHecaptchaToken] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [isRequesting, setIsRequesting] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>('');
    const {isDarkMode} = useTheme();

    const handleVerificationSuccess = (token: string) => {
        setHecaptchaToken(token);
        setError('');
    };

    const handleResetPasswordRequest = async () => {
        const emailError = validateEmail(email);
        if (emailError) {
            setError(emailError);
            return;
        }

        const captchaError = validateHCaptcha(hcaptchaToken);
        if (captchaError) {
            setError(captchaError);
            return;
        }

        setIsRequesting(true);
        setError('');
        setSuccessMessage('');

        try {
            const resetPasswordLink = `${window.location.origin}/reset-password/`;
            const response = await axios.post(AuthAPIUrls.REQUEST_PASSWORD_RESET, {
                email,
                resetPasswordLink,
                hcaptchaToken,
                url: getWebsiteUrl()
            });

            if (response.status === 200) {
                setSuccessMessage(response.data || 'Check your email for the password reset link');
                setEmail(''); // Clear email field after successful request
                setHecaptchaToken(''); // Reset captcha
            }
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    switch (error.response.status) {
                        case 400:
                            const validationError = validate400Errors(error);
                            setError(validationError || 'Invalid email format');
                            break;
                        case 404:
                            setError('No account found with this email address');
                            break;
                        case 429:
                            setError('Too many requests. Please try again later');
                            break;
                        case 500:
                            if (error.response.data?.includes('FailedToSendRestPasswordLink')) {
                                setError('Unable to send reset password email. Please try again later');
                            } else if (error.response.data?.includes('FailedToInjectPassword')) {
                                setError('System error: Failed to process password reset');
                            } else {
                                setError('An unexpected error occurred. Please try again later');
                            }
                            break;
                        default:
                            setError('An error occurred while processing your request');
                    }
                } else if (error.request) {
                    setError('Unable to connect to the server. Please check your internet connection');
                }
            } else {
                setError('An unexpected error occurred');
            }
            console.error('Password reset request error:', error);
        } finally {
            setIsRequesting(false);
        }
    };

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    return <>
        <Helmet>
            <title>Revise Wizard - Reset Password Request</title>
            <meta name="description" content="Request a password reset link"/>
            <link rel="canonical" href={getWebsiteUrl() + Url.RESET_PASSWORD_REQUEST}/>
        </Helmet>
        <div className="flex flex-col items-center justify-center flex-grow py-6 overflow-hidden md:py-8"
             style={getCurrentStyles(isDarkMode)}>
            <motion.h1
                initial={{opacity: 0, y: -20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.5}}
                className="mb-8 text-4xl font-extrabold text-center"
            >
                Reset Password
            </motion.h1>

            {error && (
                <div className="px-4 py-2 mb-4 text-center text-white bg-red-500 rounded w-96">
                    {error}
                </div>
            )}

            {successMessage && (
                <div className="px-4 py-2 mb-4 text-center text-white bg-green-500 rounded w-96">
                    {successMessage}
                </div>
            )}

            <div className="mb-4 w-96">
                <label className="block mb-2 text-lg">Email:</label>
                <input
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    className="w-full px-4 py-2 text-black rounded"
                    style={getCurrentInputStyles(isDarkMode)}
                />
            </div>

            <div className="mb-4">
                <HCaptcha
                    sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY || ''}
                    onVerify={(token) => handleVerificationSuccess(token)}
                />
            </div>

            <motion.button
                whileHover={{scale: 1.05}}
                whileTap={{scale: 0.95}}
                className="px-4 py-2 text-white transition duration-300 bg-green-500 rounded"
                onClick={handleResetPasswordRequest}
                disabled={isRequesting}
                style={getCurrentButtonStyles(isDarkMode)}
            >
                {isRequesting ? 'Requesting...' : 'Reset Password'}
            </motion.button>
        </div>
    </>;
};

export default ResetPasswordRequest;
