import axios from 'axios';

export const validateEmail = (email: string): string => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
        return 'Email cannot be empty';
    } else if (!emailRegex.test(email)) {
        return 'Invalid email address';
    }
    return '';
};

export const validatePassword = (password: string): string => {
    if (!password.trim()) {
        return 'Password cannot be empty';
    }
    
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!-])(?=\S+$).{8,}$/;
    if (!passwordRegex.test(password)) {
        return 'Password must contain at least one lowercase letter, one uppercase letter, one number, one special character (@#$%^&+=!-), and be at least 8 characters long';
    }
    return '';
};

export const validatePasswordMatch = (password: string, confirmPassword: string): string => {
    if (password !== confirmPassword) {
        return 'Passwords do not match';
    }
    return '';
};

export const validateHCaptcha = (hcaptchaToken: string): string => {
    if (!hcaptchaToken) {
        return 'Please complete the CAPTCHA';
    }
    return '';
};

export const validate400Errors = (errorMessage: any): string => {
    if (errorMessage.response && errorMessage.response.data && errorMessage.response.data.errors) {
        const errors = errorMessage.response.data.errors;
        if (Array.isArray(errors) && errors.length > 0) {
            return errors.map((error: any) => error.message).join(', ');
        }
    }
    return 'An unknown error occurred';
};

export const validateGoogleSignInError = (error: any): string => {
    if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 400 && error.response.data?.type === 'DECODE_ERROR') {
            return 'Invalid Google token';
        } else if (error.response.status === 401) {
            return error.response.data.message || 'Unauthorized: Invalid or expired token';
        }
        return validate400Errors(error);
    }
    return 'Network error occurred during Google sign-in';
};