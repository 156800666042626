import axios from "axios";
import { NavigateFunction } from "react-router";
import { AuthAPIUrls } from "./APIUrls";
import { setJWT } from "./Cookies";
import { HTTPSuccessCode } from "./HTTPCode";
import Url, { getWebsiteUrl } from "./Url";
import { validateGoogleSignInError } from './validationUtils';

export function initializeGoogleSignIn(clientId: string, navigate: NavigateFunction, setErrorMessage: (message: string) => void) {
    if (window.google) {
        window.google.accounts.id.initialize({
            client_id: clientId,
            callback: (response: any) => handleCredentialResponse(response, navigate, setErrorMessage),
        });

        window.google.accounts.id.renderButton(
            document.getElementById("google-signin-button"),
            {
                theme: "outline",
                size: "large",
                text: "continue_with",
                locale: "en",
            }
        );
    }
}

const handleCredentialResponse = async (
    response: any, 
    navigate: NavigateFunction,
    setErrorMessage: (message: string) => void
) => {
    navigate(Url.GOOGLE_LOADING);

    if (!response.credential) {
        setErrorMessage('Missing Google credentials');
        navigate(Url.LOGIN);
        return;
    }

    try {
        const apiResponse = await axios.post(AuthAPIUrls.GOOGLE, {
            jwt: response.credential,
            url: getWebsiteUrl()
        });

        if (apiResponse.status === HTTPSuccessCode.ACCEPTED) {
            setJWT('jwt', apiResponse.data);
            navigate(Url.HOME);
            window.location.reload();
        } else {
            throw new Error('Unexpected response');
        }
    } catch (error: any) {
        const errorMessage = validateGoogleSignInError(error);
        setErrorMessage(errorMessage);
        console.error('Google sign-in error:', errorMessage);
        navigate(Url.LOGIN);
    }
};