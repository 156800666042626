import React from 'react';
import { Wrench, ShieldAlert } from 'lucide-react';
import {useTheme} from "../components/ThemeContext";
import {getCurrentStyles} from "../utils/Common";

interface MaintenanceWrapperProps {
    children: React.ReactNode;
    isAdmin: boolean;
}


const MaintenanceWrapper: React.FC<MaintenanceWrapperProps> = ({ children, isAdmin }) => {
    // If maintenance mode is active and user is not admin, show maintenance page
    if (process.env.REACT_APP_MAINTENANCE_MODE === 'true' && !isAdmin) {
        return <MaintenancePage />;
    }

    return <>{children}</>;
};

const MaintenancePage: React.FC = () => {
    const { isDarkMode } = useTheme();

    return (
        <div
            className="flex flex-col items-center justify-center min-h-screen overflow-hidden"
            style={getCurrentStyles(isDarkMode)}
        >
            <div className="max-w-2xl w-full space-y-8 text-center">
                {/* Animated Wrench Icon */}
                <div className="animate-bounce mb-8">
                    <Wrench
                        className="mx-auto h-16 w-16 text-green-600 dark:text-green-500"
                    />
                </div>

                {/* Main Content */}
                <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-8 backdrop-blur-sm bg-opacity-90 dark:bg-opacity-90">
                    <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
                        We're Currently Under Maintenance
                    </h1>

                    <p className="text-xl text-gray-600 dark:text-gray-300 mb-8">
                        We're working hard to improve Revise Wizard and will be back soon with new features and improvements.
                    </p>

                    {/* Expected Duration Section */}
                    <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-6">
                        <div className="flex items-center justify-center space-x-2 text-yellow-600 dark:text-yellow-500 mb-4">
                            <ShieldAlert className="h-6 w-6" />
                            <span className="font-semibold">Expected Duration</span>
                        </div>
                        <p className="text-gray-600 dark:text-gray-300">
                            Our maintenance is scheduled to complete within the next few hours.
                            Thank you for your patience!
                        </p>
                    </div>
                </div>

                {/* Contact Information */}
                <div className="mt-8 text-sm text-gray-500 dark:text-gray-400">
                    <p>If you need immediate assistance, please contact support</p>
                </div>
            </div>
        </div>
    );
};

export default MaintenanceWrapper;