import React from 'react';
import {useTheme} from "../../components/ThemeContext";
import {Helmet} from "react-helmet-async";
import {getWebsiteUrl} from "../../utils/Url";
import Url from "../../utils/Url";
import {getCurrentStyles} from "../../utils/Common";

const UserAgreement = () => {
    const {isDarkMode} = useTheme();

    return <>
        <Helmet>
            <title>Revise Wizard - User Agreement</title>
            <meta name="description"
                  content="The user agreement for Revise Wizard, which outlines the terms and conditions for using the platform."/>
            <link rel="canonical" href={getWebsiteUrl() + Url.USER_AGREEMENT}/>
        </Helmet>
        <div className="flex-grow flex flex-col items-center justify-center overflow-hidden py-6 md:py-8 text-white p-8 pb-24"
             style={getCurrentStyles(isDarkMode)}>
            <h1 className="text-2xl font-bold mb-4">User Agreement</h1>
            <p>Welcome to Revise Wizard. By using our website located at https://www.revisewizard.com/ ("Site"), you
                agree to comply with and be bound by the following User Agreement. Please review the following terms
                carefully. If you do not agree to these terms, you should not use this Site.</p>
            <h2 className="text-xl font-semibold mt-4">1. Description of Service</h2>
            <p>Revise Wizard is dedicated to helping students improve their math grades through interactive worksheets,
                personalized learning experiences, and features like exam paper retrieval.</p>
            <h2 className="text-xl font-semibold mt-4">2. User Responsibilities</h2>
            <ul className="list-disc list-inside">
                <li>Users must not resell any resources obtained from Revise Wizard, including but not limited to topic
                    booklets.
                </li>
                <li>Users must not engage in any form of theft, impersonation, or other illegal activities.</li>
            </ul>
            <h2 className="text-xl font-semibold mt-4">3. Account Creation</h2>
            <p>To create an account, users must provide a valid email address and a password.</p>
            <h2 className="text-xl font-semibold mt-4">4. Prohibited Activities</h2>
            <ul className="list-disc list-inside">
                <li>Engaging in any form of theft or impersonation.</li>
                <li>Reselling or distributing resources from Revise Wizard without authorization.</li>
                <li>Engaging in any illegal activities.</li>
            </ul>
            <h2 className="text-xl font-semibold mt-4">5. Intellectual Property</h2>
            <p>All content on this site, including worksheets and topic booklets, is the property of Revise Wizard.
                Users
                may use the content for personal use but are not permitted to release or distribute it without
                permission.</p>
            <h2 className="text-xl font-semibold mt-4">6. Termination</h2>
            <p>Revise Wizard reserves the right to terminate user accounts for any violations of this agreement,
                including
                theft or impersonation.</p>
            <h2 className="text-xl font-semibold mt-4">7. Changes to This Agreement</h2>
            <p>Revise Wizard reserves the right to modify this User Agreement at any time. Changes will be posted on
                this
                page and your continued use of the Site after such changes constitutes your acceptance of the new
                terms.</p>
        </div>
    </>;
};

export default UserAgreement;
