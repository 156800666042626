import axios from 'axios';
import React, { useRef, useState } from 'react';
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router";
import { useTheme } from "../../components/ThemeContext";
import { AuthAPIUrls } from "../../utils/APIUrls";
import { getCurrentButtonStyles, getCurrentStyles } from "../../utils/Common";
import { setJWT } from "../../utils/Cookies";
import Url, { getWebsiteUrl } from "../../utils/Url";

const Verification = () => {
    const {email, verificationType} = useParams<{ email: string; verificationType: string; }>();

    const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
    const [error, setError] = useState('');
    const [info, setInfo] = useState(''); // State variable to show info message
    const [isVerifying, setIsVerifying] = useState(false); // State to track verification process
    const navigate = useNavigate();
    const inputRefs = useRef<HTMLInputElement[]>([]);
    const {isDarkMode} = useTheme();

    const handleVerification = async () => {
        setIsVerifying(true);
        try {
            const response = await axios.post(AuthAPIUrls.VERIFY, {
                email: email,
                verificationCode: verificationCode.join(''),
                type: verificationType,
                url: getWebsiteUrl()
            });

            if (response.status === 200) {
                if (verificationType === 'signup') {
                    setInfo('Successfully verified, logging in');
                    let jwt = response.data;
                    setJWT('jwt', jwt);
                    navigate('/');
                    window.location.reload();
                } else {
                    setInfo('Successfully verified, logging in');
                    let jwt = response.data;
                    setJWT('jwt', jwt);
                    navigate('/');
                    window.location.reload();
                }

                setError('');
                setInfo('');
                setVerificationCode(['', '', '', '', '', '']);
            } else {
                // Handle unsuccessful verification
                console.error('Error during verification:', response.data);
                setError('Invalid verification code');
            }
        } catch (error) {
            // Handle error
            console.error('Error during verification:', error);
            setError('Invalid verification code');
        } finally {
            setIsVerifying(false);
        }
    };

    // Function to handle the change in each input box
    const handleChange = (index: number, value: string) => {
        const newVerificationCode = [...verificationCode];
        newVerificationCode[index] = value;
        setVerificationCode(newVerificationCode);

        // Move focus to next input box if value is entered
        if (value && index < 5) {
            inputRefs.current[index + 1].focus();
        }
    };

    // Function to handle key down event
    const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Backspace' && !verificationCode[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    // Function to handle paste event
    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const clipboardData = e.clipboardData.getData('text');
        const pastedData = clipboardData.replace(/\s+/g, '').slice(0, 6);
        const pastedChars = pastedData.split('');
        const newVerificationCode = [...verificationCode];
        for (let i = 0; i < pastedChars.length; i++) {
            newVerificationCode[i] = pastedChars[i];
            if (inputRefs.current[i]) {
                inputRefs.current[i].value = pastedChars[i];
            }
        }
        setVerificationCode(newVerificationCode);
        if (pastedChars.length < 6) {
            inputRefs.current[pastedChars.length].focus();
        }
    };

    return <>
        <Helmet>
            <title>Revise Wizard - Verification</title>
            <meta name="description" content="Input verification code to verify your account"/>
            <link rel="canonical" href={getWebsiteUrl() + Url.SIGN_UP}/>
        </Helmet>
        <div className="flex flex-col items-center justify-center min-h-screen overflow-hidden text-black"
             style={getCurrentStyles(isDarkMode)}>
            <h2 className={`text-2xl mb-4 ${isDarkMode ? 'text-white' : 'text-black'}`}>
                Enter verification code:
            </h2>
            <div className="flex items-center justify-center mb-4">
                {Array.from({length: 6}, (_, index) => (
                    <input
                        key={index}
                        type="text"
                        maxLength={1}
                        value={verificationCode[index]}
                        onChange={(e) => handleChange(index, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(index, e)}
                        onPaste={handlePaste}
                        ref={el => inputRefs.current[index] = el!}
                        inputMode="text"
                        autoCapitalize="off"
                        className={`w-12 h-12 border rounded-md text-3xl mx-2 text-center focus:outline-none focus:border-blue-500 
        ${isDarkMode ? 'text-white bg-gray-800 border-gray-600' : 'text-black bg-white border-gray-300'}`}
                    />
                ))}
            </div>
            <button
                onClick={handleVerification}
                className="px-4 py-2 text-white transition duration-300 bg-green-500 rounded hover:bg-green-600"
                disabled={isVerifying}
                style={getCurrentButtonStyles(isDarkMode)}
            >
                {isVerifying ? (
                    <div className="flex space-x-1">
                        <span className="animate-bounce">.</span>
                        <span className="animate-bounce animation-delay-200">.</span>
                        <span className="animate-bounce animation-delay-400">.</span>
                    </div>
                ) : 'Verify'}
            </button>
            {error && <p className="mt-2 text-red-500">{error}</p>}
            {info && <p className="mt-2 text-green-500">{info}</p>}
        </div>
    </>;
};

export default Verification;
