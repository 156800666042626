import process from 'process';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter as Router} from "react-router-dom";
import {HelmetProvider} from "react-helmet-async";
import TagManager from 'react-gtm-module';

window.process = process;

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <Router>
        <HelmetProvider>
            <App/>
        </HelmetProvider>
    </Router>
);
