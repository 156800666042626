import React from 'react';
import { Helmet } from "react-helmet-async";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from 'react-router-dom';
import { ReactTyped } from "react-typed";
import { useTheme } from "../components/ThemeContext";
import { getCurrentButtonStyles, getCurrentStyles } from "../utils/Common";
import { getCookie } from "../utils/Cookies";
import Url, { getWebsiteUrl } from "../utils/Url";

const Home: React.FC = () => {
    const jwtToken = getCookie('jwt');
    const {isDarkMode} = useTheme();

    const currentBoldTextStyles = isDarkMode ? {
        fontWeight: 'bold',
        color: '#86C232'
    } : {
        fontWeight: 'bold',
        color: '#130200'
    }

    const logoPath = isDarkMode ? "/logos/dark_mode_logo.svg" : "/logos/white_mode_logo.svg";

    return (
        <>
            <Helmet>
                <title>Revise Wizard - Home</title>
                <meta name="description"
                      content="The home page of Revise Wizard, a platform that aims to increase productivity by cutting prep time and distractions."/>
                <link rel="canonical" href={getWebsiteUrl() + Url.HOME}/>
            </Helmet>
            <div className="flex flex-col items-center flex-grow px-4 py-6 sm:px-6 md:px-8"
                 style={getCurrentStyles(isDarkMode)}>

                <div className="flex flex-col items-center justify-center flex-1">
                    <div className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl">
                        <img src={logoPath} alt="Revise Wizard Logo" className="logo-image"/>
                    </div>

                    <div className="w-full">
                        <h1 className="mb-3 text-2xl font-bold text-center sm:text-3xl md:text-4xl lg:text-5xl">
                            Welcome to <span
                            style={{color: currentBoldTextStyles.color, fontWeight: currentBoldTextStyles.fontWeight}}>Revise Wizard</span>
                        </h1>
                    </div>

                    <div className="text-lg font-bold text-center sm:text-xl md:text-2xl"
                         style={{color: currentBoldTextStyles.color, fontWeight: currentBoldTextStyles.fontWeight}}>
                        We support:
                        <ReactTyped
                            strings={['Math', 'Economics', 'Computer Science']}
                            typeSpeed={40}
                            backSpeed={50}
                            loop
                            className="ml-2"
                            style={{color: currentBoldTextStyles.color}}
                        />
                    </div>

                    {!jwtToken && (
                        <div className="flex justify-center mt-4 space-x-4">
                            <Link to={Url.SIGN_UP}>
                                <button
                                    className="px-6 py-3 transition duration-300 transform rounded hover:bg-green-600 hover:scale-110"
                                    style={getCurrentButtonStyles(isDarkMode)}>
                                    Sign Up Now
                                </button>
                            </Link>
                            <Link to={Url.LOGIN}>
                                <button
                                    className="px-6 py-3 transition duration-300 transform rounded hover:bg-green-600 hover:scale-110"
                                    style={getCurrentButtonStyles(isDarkMode)}>
                                    Login Now
                                </button>
                            </Link>
                        </div>
                    )}

                    {jwtToken && (
                        <div className="flex justify-center mt-4 space-x-4">
                            <Link to={Url.MATH}>
                                <button
                                    className="px-6 py-3 transition duration-300 transform rounded hover:bg-green-600 hover:scale-110"
                                    style={getCurrentButtonStyles(isDarkMode)}>
                                    Go to Math
                                </button>
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Home;