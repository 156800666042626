export function getButtonClass(level: string, selectedLevel: string, isDarkMode: boolean): string {
    const baseClass = 'inline-flex items-center px-4 py-2 rounded-full cursor-pointer transition-colors duration-300';
    const selectedDarkModeClass = 'bg-green-700 text-white';
    const unselectedDarkModeClass = 'bg-gray-800 text-white hover:bg-green-700 hover:text-white';
    const selectedLightModeClass = 'bg-green-400 text-black';
    const unselectedLightModeClass = 'bg-gray-200 text-black hover:bg-green-400 hover:text-black';

    // Determine the classes based on the selected level and mode
    const modeClass = selectedLevel === level
        ? (isDarkMode ? selectedDarkModeClass : selectedLightModeClass)
        : (isDarkMode ? unselectedDarkModeClass : unselectedLightModeClass);

    return `${baseClass} ${modeClass}`;
}

export const getCustomStyles = (isDarkMode: boolean) => ({
    singleValue: (provided: any, state: any) => ({
        ...provided,
        color: '#000000'
    }),
    multiValue: (provided: any) => ({
        ...provided,
        backgroundColor: isDarkMode ? '#61892F' : '#747474', // Adjust background color based on theme
    }),
    multiValueLabel: (provided: any) => ({
        ...provided,
        color: 'white',
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isSelected ? (isDarkMode ? '#222629' : '#EEEEEE') : 'white',
        color: state.isSelected ? (isDarkMode ? '#FFFFFF' : '#747474') : 'black',
        '&:hover': {
            backgroundColor: isDarkMode ? '#61892F' : '#86C232',
            color: 'white',
        },
    }),
});

export const darkModeStyles = {
    backgroundColor: '#222629',
    color: '#FFFFFF'
};

export const whiteModeStyles = {
    backgroundColor: '#EEEEEE',
    color: '#747474'
};

export const getCurrentButtonStyles = (isDarkMode: boolean) => ({
    backgroundColor: isDarkMode ? '#61892F' : '#86C232',
    color: isDarkMode ? 'white' : '#000000',
});

export const getCurrentStyles = (isDarkMode: boolean) => isDarkMode ? darkModeStyles : whiteModeStyles;

const inputDarkModeStyles = {
    backgroundColor: '#333',
    color: '#FFF',
    borderColor: '#555'
};

const inputWhiteModeStyles = {
    backgroundColor: '#FFF',
    color: '#747474',
    borderColor: '#000'
};

export const getCurrentInputStyles = (isDarkMode: boolean) => isDarkMode ? inputDarkModeStyles : inputWhiteModeStyles;