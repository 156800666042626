import Cookies, {CookieSetOptions} from "universal-cookie";
import {jwtDecode} from 'jwt-decode';

let cookies = new Cookies();

export function setJWT(name: string, value: string, options?: CookieSetOptions | undefined) {
    try {
        let decoded: any;
        try {
            decoded = jwtDecode(value);
        } catch (e) {
            console.error('Invalid JWT token', e);
            return;
        }

        if (decoded && decoded.exp) {
            options = {...options, expires: new Date(decoded.exp * 1000)};
        }

        cookies.set(name, value, {path: "/", ...options});
    } catch (e) {
        alert("Error: " + e);
    }
}

export function setCookie(name: string, value: string, options?: CookieSetOptions | undefined) {
    try {
        cookies.set(name, value, {path: "/", ...options});
    } catch (e) {
        alert("Error: " + e);
    }
}

export function getCookie(name: string): string | null {
    const cookieValue = cookies.get(name);
    return cookieValue === undefined || cookieValue === null ? null : cookieValue as string;
}

export function removeCookie(name: string) {
    cookies.remove(name, {path: "/"});
}
