import HCaptcha from '@hcaptcha/react-hcaptcha';
import axios from 'axios';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';
import { useTheme } from "../../components/ThemeContext";
import { AuthAPIUrls, VerificationAPIUrls } from "../../utils/APIUrls";
import { getCurrentButtonStyles, getCurrentInputStyles, getCurrentStyles } from "../../utils/Common";
import { initializeGoogleSignIn } from '../../utils/googleSignIn';
import Url, { getWebsiteUrl } from "../../utils/Url";
import { validateEmail, validateHCaptcha, validatePassword, validatePasswordMatch } from "../../utils/validationUtils";

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [hcaptchaToken, setHecaptchaToken] = useState('');
    const [error, setError] = useState('');
    const [isVerifying, setIsVerifying] = useState(false);
    const [isSigningUp, setIsSigningUp] = useState(false);
    const navigate = useNavigate();
    const { isDarkMode } = useTheme();

    useEffect(() => {
        initializeGoogleSignIn(process.env.REACT_APP_GOOGLE_CLIENT_ID || '', navigate, setError);
    }, [navigate]);

    const handleVerificationSuccess = async (token: React.SetStateAction<string>, ekey: string) => {
        setIsVerifying(true);
        try {
            const response = await axios.post(VerificationAPIUrls.VERIFY_HCAPTCHA, {
                hcaptchaToken: token,
                ekey: ekey,
                url: getWebsiteUrl()
            });
            if (response.status === 200) {
                setHecaptchaToken(token);
                setError('');
            } else {
                setError('An error occurred during hcaptcha verification');
            }
        } catch (error) {
            setError('An error occurred during hcaptcha verification');
        } finally {
            setIsVerifying(false);
        }
    };

    const handleSignUp = async () => {
        const emailError = validateEmail(email);
        if (emailError) {
            setError(emailError);
            return;
        }

        const passwordError = validatePassword(password);
        if (passwordError) {
            setError(passwordError);
            return;
        }

        const passwordMatchError = validatePasswordMatch(password, confirmPassword);
        if (passwordMatchError) {
            setError(passwordMatchError);
            return;
        }

        const captchaError = validateHCaptcha(hcaptchaToken);
        if (captchaError) {
            setError(captchaError);
            return;
        }

        if (!acceptTerms) {
            setError('You must accept the terms and conditions to sign up.');
            return;
        }

        setIsSigningUp(true);
        try {
            const response = await axios.post(AuthAPIUrls.SIGNUP, {
                email,
                password,
                hcaptchaToken,
                url: getWebsiteUrl()
            });
    
            if (response.status === 201) {
                navigate(`/verification/${email}/signup`);
            } else {
                throw new Error('Unexpected response from server');
            }
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    switch (error.response.status) {
                        case 409:
                            setError('This email is already registered. Please use a different email.');
                            break;
                        case 400:
                            const apiErrors = error.response.data?.errors;
                            if (apiErrors) {
                                const errorMessages = Object.values(apiErrors).join(' ');
                                setError(errorMessages);
                            } else {
                                setError('Invalid data submitted. Please check your inputs.');
                            }
                            break;
                        default:
                            setError(error.response.data || 'An error occurred during signup, please report this issue.');
                            break;
                    }
                } else if (error.request) {
                    setError('No response from server. Please check your connection.');
                } else {
                    setError('An unexpected error occurred');
                }
            } else {
                setError('An unexpected error occurred');
            }
        } finally {
            setIsSigningUp(false);
        }
    };

    return <>
        <Helmet>
            <title>Revise Wizard - Sign Up</title>
            <meta name="description" content="Sign up for a Revise Wizard account"/>
            <link rel="canonical" href={getWebsiteUrl() + Url.SIGN_UP}/>
        </Helmet>
        <div className="flex flex-col items-center justify-center flex-grow py-6 overflow-hidden md:py-8"
             style={getCurrentStyles(isDarkMode)}>
            <motion.h1
                initial={{opacity: 0, y: -20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.5}}
                className="mb-8 text-4xl font-extrabold text-center"
                style={{color: getCurrentStyles(isDarkMode).color}}
            >
                Sign Up
            </motion.h1>

            <div id="google-signin-button"></div>

            {error && (
                <div className="px-4 py-2 mb-4 text-center text-white bg-red-500 rounded w-96">
                    {error}
                </div>
            )}

            <div className="mb-4 w-96">
                <label className="block mb-2 text-lg" style={{color: getCurrentStyles(isDarkMode).color}}>Email:</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-4 py-2 rounded"
                    style={getCurrentInputStyles(isDarkMode)}
                />
            </div>

            <div className="mb-4 w-96">
                <label className="block mb-2 text-lg"
                       style={{color: getCurrentStyles(isDarkMode).color}}>Password:</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-4 py-2 rounded"
                    style={getCurrentInputStyles(isDarkMode)}
                />
            </div>

            <div className="mb-4 w-96">
                <label className="block mb-2 text-lg" style={{color: getCurrentStyles(isDarkMode).color}}>Confirm
                    Password:</label>
                <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full px-4 py-2 rounded"
                    style={getCurrentInputStyles(isDarkMode)}
                />
            </div>

            <div className="mb-4 w-96">
                <input
                    type="checkbox"
                    checked={acceptTerms}
                    onChange={() => setAcceptTerms(!acceptTerms)}
                />
                <span className="ml-2" style={{color: getCurrentStyles(isDarkMode).color}}>
                    I accept the <Link to="/user-agreement"
                                       className="text-blue-500 hover:underline">User Agreement</Link>,
                    <Link to="/terms-of-service" className="text-blue-500 hover:underline"> Terms of Service</Link>, and
                    <Link to="/privacy-policy" className="text-blue-500 hover:underline"> Privacy Policy</Link>.
                </span>
            </div>

            <div className="mb-4">
                <HCaptcha
                    sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY || ''}
                    onVerify={(token, ekey) => handleVerificationSuccess(token, ekey)}
                />
            </div>

            <motion.button
                whileHover={{scale: 1.05}}
                whileTap={{scale: 0.95}}
                className="px-4 py-2 transition duration-300 rounded"
                onClick={handleSignUp}
                style={getCurrentButtonStyles(isDarkMode)}
                disabled={isVerifying || isSigningUp}
            >
                {isVerifying ? 'Please wait...' : isSigningUp ? (
                    <div className="flex space-x-1">
                        <span className="animate-bounce">.</span>
                        <span className="animate-bounce animation-delay-200">.</span>
                        <span className="animate-bounce animation-delay-400">.</span>
                    </div>
                ) : 'Sign Up'}
            </motion.button>
        </div>
    </>;
};

export default SignUp;
