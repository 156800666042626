import React from 'react';
import {useTheme} from "../../components/ThemeContext";
import {Helmet} from "react-helmet-async";
import Url from "../../utils/Url";
import {getWebsiteUrl} from "../../utils/Url";
import {getCurrentStyles} from "../../utils/Common";

const PrivacyPolicy = () => {
    const {isDarkMode} = useTheme();

    return (
        <>
            <Helmet>
                <title>Revise Wizard - Privacy Policy</title>
                <meta
                    name="description"
                    content="Our Privacy Policy outlines how Revise Wizard collects, uses, and safeguards your personal information."
                />
                <link rel="canonical" href={getWebsiteUrl() + Url.PRIVACY_POLICY}/>
            </Helmet>
            <div
                className="flex-grow flex flex-col items-center justify-center overflow-hidden py-6 md:py-8 text-white p-8 pb-24"
                style={getCurrentStyles(isDarkMode)}
            >
                <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
                <p>
                    At Revise Wizard ("we", "us", "our"), we are committed to protecting your privacy. This Privacy
                    Policy explains how we collect, use, store, and safeguard your personal data in compliance with
                    applicable privacy laws.
                </p>

                <h2 className="text-xl font-semibold mt-4">1. Information We Collect</h2>
                <p>We may collect the following information from you:</p>
                <ul className="list-disc list-inside">
                    <li>Email address</li>
                    <li>Encrypted password (using ARAGON_2 encryption with salt and pepper)</li>
                </ul>

                <h2 className="text-xl font-semibold mt-4">2. How We Use Your Information</h2>
                <p>Your personal information is used for the following purposes:</p>
                <ul className="list-disc list-inside">
                    <li>We encrypt passwords with ARAGON_2 encryption to enhance security.</li>
                    <li>Your email address is used solely for account identification and to track the number of users.
                        We do not use your email for marketing or other purposes.
                    </li>
                </ul>

                <h2 className="text-xl font-semibold mt-4">3. Data Sharing</h2>
                <p>We do not share, sell, or disclose your personal information to third parties under any
                    circumstances.</p>

                <h2 className="text-xl font-semibold mt-4">4. Your Rights</h2>
                <ul className="list-disc list-inside">
                    <li>You have the right to reset your password at any time.</li>
                    <li>You have the right to access and use our site freely, in accordance with the site's terms and
                        conditions.
                    </li>
                </ul>

                <h2 className="text-xl font-semibold mt-4">5. Data Security</h2>
                <p>We take data security seriously and employ industry-standard encryption methods, specifically
                    ARAGON_2 with salt and pepper, to protect your passwords and ensure the confidentiality of your
                    information.</p>

                <h2 className="text-xl font-semibold mt-4">6. Changes to This Privacy Policy</h2>
                <p>
                    We reserve the right to modify this Privacy Policy at any time. Any changes will be reflected on
                    this page, and your continued use of the website after such changes will constitute acceptance of
                    the revised terms.
                </p>
            </div>
        </>
    );
};

export default PrivacyPolicy;