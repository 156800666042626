import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {motion} from 'framer-motion';
import {FaSpinner} from "@react-icons/all-files/fa/FaSpinner";
import axios from "axios";
import {useNavigate} from "react-router";
import {getCookie} from "../../utils/Cookies";
import {InfoAPIUrls, UploadAPIUrls} from "../../utils/APIUrls";

const QuestionUploader = () => {
    const [topics, setTopics] = useState<{ value: string; label: string }[]>([]);
    const [selectedTopic, setSelectedTopic] = useState<{ value: string; label: string } | null>(null);
    const [loadingTopics, setLoadingTopics] = useState<boolean>(false);
    const [selectedLevel, setSelectedLevel] = useState<'gcse' | 'aslevel' | 'alevel'>('gcse');
    const [mark, setMark] = useState<number>(0);
    const [marks, setMarks] = useState<number[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [questionFile, setQuestionFile] = useState<File | null>(null);
    const [answerFile, setAnswerFile] = useState<File | null>(null);
    const [questionFiles, setQuestionFiles] = useState<File[]>([]);
    const [answerFiles, setAnswerFiles] = useState<File[]>([]);
    const [uploadMode, setUploadMode] = useState<'individual' | 'batch'>('individual');
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchTopics(selectedLevel).then(r => r);
    }, [selectedLevel]);

    const fetchTopics = async (level: string) => {
        setLoadingTopics(true);
        try {
            const response = await axios.get(InfoAPIUrls.TOPIC_LIST, {params: {level}});
            const topicsData = response.data.topics.map((topic: string) => ({value: topic, label: topic}));
            setTopics(topicsData);
        } catch (error) {
            console.error('Error fetching topics:', error);
            setTopics([]); // Clear topics on error
        } finally {
            setLoadingTopics(false);
        }
    };

    const handleLevelChange = (newLevel: 'gcse' | 'aslevel' | 'alevel') => {
        setSelectedLevel(newLevel);
        fetchTopics(newLevel).then(r => r);
    };

    const handleTopicChange = (selectedOption: any) => {
        setSelectedTopic(selectedOption);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, setFile: React.Dispatch<React.SetStateAction<File | null>>) => {
        const file = e.target.files?.[0];
        if (file) {
            setFile(file);
        }
    };

    const handleBatchFileChange = (e: React.ChangeEvent<HTMLInputElement>, setFiles: React.Dispatch<React.SetStateAction<File[]>>, setMarks: React.Dispatch<React.SetStateAction<number[]>>) => {
        const files = e.target.files;
        if (files) {
            setFiles(prevFiles => [...prevFiles, ...Array.from(files)]);
            setMarks(prevMarks => [...prevMarks, ...new Array(files.length).fill(0)]); // Initialize marks array with 0s
        }
    };

    const handleMarkChange = (index: number, value: number) => {
        const newMarks = [...marks];
        newMarks[index] = value;
        setMarks(newMarks);
    };

    const handleUpload = async () => {
        setLoading(true);
        const jwt = getCookie('jwt');

        if (!selectedTopic || (uploadMode === 'individual' && (!questionFile || !answerFile || !mark)) || (uploadMode === 'batch' && (questionFiles.length !== answerFiles.length || questionFiles.length === 0))) {
            alert('Please fill in all fields');
            setLoading(false);
            return;
        }

        if (jwt === null) {
            alert('You need to be logged in to upload questions');
            navigate('/login');
            return;
        }

        try {
            if (uploadMode === 'individual') {
                const formData = new FormData();
                formData.append('question', questionFile as Blob);
                formData.append('questionAnswer', answerFile as Blob);
                formData.append('topic', selectedTopic.value);
                formData.append('level', selectedLevel);
                formData.append('mark', mark.toString());
                formData.append('jwt', jwt);

                const response = await axios.post(UploadAPIUrls.QUESTIONS, formData);
                handleResponse(response);
            } else {
                for (let i = 0; i < questionFiles.length; i++) {
                    const formData = new FormData();
                    formData.append('question', questionFiles[i]);
                    formData.append('questionAnswer', answerFiles[i]);
                    formData.append('topic', selectedTopic.value);
                    formData.append('level', selectedLevel);
                    formData.append('mark', marks[i].toString());
                    formData.append('jwt', jwt);

                    const response = await axios.post(UploadAPIUrls.QUESTIONS, formData);
                    handleResponse(response);
                }
            }
        } catch (error: any) {
            console.error('Error uploading question:', error);
            if (error.response && error.response.status === 403) {
                alert('You do not have permission to upload questions');
            } else {
                const errorMessage = error.response?.data?.message;
                if (errorMessage) {
                    alert('An error occurred while uploading the question' + errorMessage);
                } else {
                    alert('An error occurred while uploading the question' + error);
                }
            }
        } finally {
            setLoading(false);
        }
    };

    const handleResponse = (response: any) => {
        if (response.status === 200) {
            setSuccessMessage('Question uploaded successfully');
            setTimeout(() => setSuccessMessage(null), 5000); // Clear success message after 5 seconds
            setQuestionFile(null);
            setAnswerFile(null);
            setQuestionFiles([]);
            setAnswerFiles([]);
            setMarks([]);
            setSelectedTopic(null);
            setMark(0);
        } else {
            alert(response.data);
        }
    };

    const customStyles = {
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#4299e1' : 'white',
            color: state.isSelected ? 'white' : 'black',
        }),
    };

    return (
        <div className="bg-blue-900 text-white min-h-screen flex flex-col items-center justify-center p-4">
            <h1 className="text-4xl font-extrabold mb-8 text-center">Question Uploader</h1>
            {successMessage && (
                <div className="mb-4 w-96 p-4 text-center bg-green-500 text-white rounded">
                    {successMessage}
                </div>
            )}
            <div className="mb-4 w-96">
                <label className="block text-lg mb-2">Select Level:</label>
                <div className="mb-4 space-x-4 flex items-center justify-center">
                    <label
                        className={`inline-flex items-center px-4 py-2 rounded-full cursor-pointer ${
                            selectedLevel === 'gcse' ? 'bg-green-500 text-white' : 'bg-white text-green-500 hover:bg-blue-500 hover:text-white'
                        }`}
                    >
                        <input
                            type="radio"
                            className="hidden"
                            value="gcse"
                            checked={selectedLevel === 'gcse'}
                            onChange={() => handleLevelChange('gcse')}
                        />
                        GCSE
                    </label>
                    <label
                        className={`inline-flex items-center px-4 py-2 rounded-full cursor-pointer ${
                            selectedLevel === 'aslevel' ? 'bg-green-500 text-white' : 'bg-white text-green-500 hover:bg-blue-500 hover:text-white'
                        }`}
                    >
                        <input
                            type="radio"
                            className="hidden"
                            value="aslevel"
                            checked={selectedLevel === 'aslevel'}
                            onChange={() => handleLevelChange('aslevel')}
                        />
                        AS-level
                    </label>
                    <label
                        className={`inline-flex items-center px-4 py-2 rounded-full cursor-pointer ${
                            selectedLevel === 'alevel' ? 'bg-green-500 text-white' : 'bg-white text-green-500 hover:bg-blue-500 hover:text-white'
                        }`}
                    >
                        <input
                            type="radio"
                            className="hidden"
                            value="alevel"
                            checked={selectedLevel === 'alevel'}
                            onChange={() => handleLevelChange('alevel')}
                        />
                        A-level
                    </label>
                </div>
            </div>
            <div className="mb-4 w-96">
                <label className="block text-lg mb-2">Upload Mode:</label>
                <div className="mb-4 space-x-4 flex items-center justify-center">
                    <label
                        className={`inline-flex items-center px-4 py-2 rounded-full cursor-pointer ${
                            uploadMode === 'individual' ? 'bg-green-500 text-white' : 'bg-white text-green-500 hover:bg-blue-500 hover:text-white'
                        }`}
                    >
                        <input
                            type="radio"
                            className="hidden"
                            value="individual"
                            checked={uploadMode === 'individual'}
                            onChange={() => setUploadMode('individual')}
                        />
                        Individual
                    </label>
                    <label
                        className={`inline-flex items-center px-4 py-2 rounded-full cursor-pointer ${
                            uploadMode === 'batch' ? 'bg-green-500 text-white' : 'bg-white text-green-500 hover:bg-blue-500 hover:text-white'
                        }`}
                    >
                        <input
                            type="radio"
                            className="hidden"
                            value="batch"
                            checked={uploadMode === 'batch'}
                            onChange={() => setUploadMode('batch')}
                        />
                        Batch
                    </label>
                </div>
            </div>
            <div className="mb-4 w-96">
                <label className="block text-lg mb-2">Select Topic:</label>
                {loadingTopics ? (
                    <div className="flex justify-center items-center">
                        <FaSpinner className="animate-spin"/>
                    </div>
                ) : (
                    <Select options={topics} value={selectedTopic} onChange={handleTopicChange} styles={customStyles}/>
                )}
            </div>
            {uploadMode === 'individual' ? (
                <>
                    <div className="mb-4 w-96">
                        <label className="block text-lg mb-2">Question File:</label>
                        <div
                            className="relative flex items-center justify-between bg-gray-800 border border-gray-600 rounded px-4 py-2 cursor-pointer">
                            <input
                                type="file"
                                accept=".png, .jpg, .jpeg, .pdf"
                                onChange={(e) => handleFileChange(e, setQuestionFile)}
                                className="absolute inset-0 opacity-0 w-full h-full cursor-pointer"
                            />
                            <span className="truncate">{questionFile ? questionFile.name : "No file chosen"}</span>
                            <span className="ml-2 text-green-500">Choose file</span>
                        </div>
                    </div>
                    <div className="mb-4 w-96">
                        <label className="block text-lg mb-2">Answer File:</label>
                        <div
                            className="relative flex items-center justify-between bg-gray-800 border border-gray-600 rounded px-4 py-2 cursor-pointer">
                            <input
                                type="file"
                                accept=".png, .jpg, .jpeg, .pdf"
                                onChange={(e) => handleFileChange(e, setAnswerFile)}
                                className="absolute inset-0 opacity-0 w-full h-full cursor-pointer"
                            />
                            <span className="truncate">{answerFile ? answerFile.name : "No file chosen"}</span>
                            <span className="ml-2 text-green-500">Choose file</span>
                        </div>
                    </div>
                    <div className="mb-4 w-96">
                        <label className="block text-lg mb-2">Mark:</label>
                        <input
                            type="number"
                            value={mark}
                            onChange={(e) => setMark(parseInt(e.target.value))}
                            className="w-full px-4 py-2 rounded text-white bg-gray-800 border border-gray-600"
                            min="0"
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className="mb-4 w-96">
                        <label className="block text-lg mb-2">Question Files:</label>
                        <div
                            className="relative flex items-center justify-between bg-gray-800 border border-gray-600 rounded px-4 py-2 cursor-pointer">
                            <input
                                type="file"
                                accept=".png, .jpg, .jpeg, .pdf"
                                onChange={(e) => handleBatchFileChange(e, setQuestionFiles, setMarks)}
                                multiple
                                className="absolute inset-0 opacity-0 w-full h-full cursor-pointer"
                            />
                            <span
                                className="truncate">{questionFiles.length > 0 ? `${questionFiles.length} files chosen` : "No files chosen"}</span>
                            <span className="ml-2 text-green-500">Choose files</span>
                        </div>
                    </div>
                    <div className="mb-4 w-96">
                        <label className="block text-lg mb-2">Answer Files:</label>
                        <div
                            className="relative flex items-center justify-between bg-gray-800 border border-gray-600 rounded px-4 py-2 cursor-pointer">
                            <input
                                type="file"
                                accept=".png, .jpg, .jpeg, .pdf"
                                onChange={(e) => handleBatchFileChange(e, setAnswerFiles, setMarks)}
                                multiple
                                className="absolute inset-0 opacity-0 w-full h-full cursor-pointer"
                            />
                            <span
                                className="truncate">{answerFiles.length > 0 ? `${answerFiles.length} files chosen` : "No files chosen"}</span>
                            <span className="ml-2 text-green-500">Choose files</span>
                        </div>
                    </div>
                    {questionFiles.map((file, index) => (
                        <div key={index} className="mb-4 w-96">
                            <label className="block text-lg mb-2">Mark for {file.name}:</label>
                            <input
                                type="number"
                                value={marks[index]}
                                onChange={(e) => handleMarkChange(index, parseInt(e.target.value))}
                                className="w-full px-4 py-2 rounded text-white bg-gray-800 border border-gray-600"
                                min="0"
                            />
                        </div>
                    ))}
                </>
            )}
            <motion.button
                whileHover={{scale: 1.05}}
                whileTap={{scale: 0.95}}
                className={`bg-green-500 text-white px-4 py-2 rounded transition duration-300 transform hover:scale-105 focus:outline-none ${
                    questionFile && answerFile && selectedTopic && mark > 0 ? 'bg-purple-700' : ''
                }`}
                onClick={handleUpload}
                disabled={loading || !selectedTopic || (uploadMode === 'individual' && (!questionFile || !answerFile || mark <= 0)) || (uploadMode === 'batch' && (questionFiles.length !== answerFiles.length || questionFiles.length === 0))}
            >
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {loading && (
                        <motion.div
                            animate={{
                                rotate: 360,
                                transition: {
                                    duration: 1,
                                    ease: 'linear',
                                    repeat: Infinity,
                                },
                            }}
                            style={{marginRight: '0.5rem'}}
                        >
                            <FaSpinner/>
                        </motion.div>
                    )}
                    {loading ? 'Uploading...' : 'Upload Question'}
                </div>
            </motion.button>
        </div>
    );
};

export default QuestionUploader;
