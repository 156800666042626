/**
 * Enum for all the URLs in the application
 */
enum Url {
    HOME = '/',
    USER_AGREEMENT = '/user-agreement',
    TERMS_OF_SERVICE = '/terms-of-service',
    PRIVACY_POLICY = '/privacy-policy',
    LOGGING_OUT = '/logging-out',
    MATH = '/math',
    MATH_EXAM_PAPER_RETRIEVAL = '/math/exam-paper-retrieval',
    MATH_TOPIC_PAPER_GENERATOR = '/math/topic-paper-generator',
    MATH_EXAM_QUESTION_UPLOADER = '/math/exam-question-uploader',
    MATH_EXAM_PAPER_BUILDER = '/math/exam-paper-builder',
    USER_PAGE = '/user-page',
    SETTINGS_PAGE = '/settings',
    GOOGLE_LOADING = '/google-loading',
    LOGIN = '/login',
    SIGN_UP = '/signup',
    VERIFICATION = '/verification/:email/:verificationType',
    RESET_PASSWORD_REQUEST = '/reset-password-request',
    RESET_PASSWORD = '/reset-password/:token',
    PLACEHOLDER = '',
    NOT_FOUND = '*'
}

export function getWebsiteUrl(): string {
    return process.env.REACT_APP_WEBSITE_URL || '';
}

export default Url;