import {useEffect, useState} from "react";
import axios from "axios";
import {getCookie, removeCookie} from "./Cookies";
import {NavigateFunction} from "react-router";
import {jwtDecode} from "jwt-decode";
import { UserInfoAPIUrls } from "./APIUrls";

const useAuth = (navigate: NavigateFunction | null) => {
    const [jwt, setJwt] = useState<string | null>(getCookie('jwt'));
    const [role, setRole] = useState<string | null>(null);
    const [isRoleFetched, setIsRoleFetched] = useState(false); // New state to track if role is fetched

    const isTokenExpired = (token: string) => {
        const decoded: any = jwtDecode(token);
        return decoded.exp * 1000 < Date.now();
    };

    const fetchUserRole = async (token: string): Promise<string> => {
        try {
            const response = await axios.post(UserInfoAPIUrls.USER_ROLE(token), {});
            return response.data.role;
        } catch (error) {
            console.error(error);
            return '';
        }
    };

    useEffect(() => {
        if (jwt && isTokenExpired(jwt)) {
            removeCookie('jwt');
            setJwt(null);
            window.location.reload();
        } else if (jwt && !isRoleFetched) {
            fetchUserRole(jwt).then((role) => {
                setRole(role);
                setIsRoleFetched(true);
            });
        }
    }, [jwt, isRoleFetched]);

    const logout = () => {
        removeCookie('jwt');
        setJwt(null);
        setIsRoleFetched(false);

        if (navigate) {
            navigate('/logging-out');
        }
    };

    return {jwt, role, logout};
};

export default useAuth;
