import { Crown, FileText, Settings, UserCircle } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from "react-router";
import { animated, useSpring } from 'react-spring';
import { useTheme } from '../components/ThemeContext';
import { UserInfoAPIUrls } from "../utils/APIUrls";
import { getCurrentStyles } from '../utils/Common';
import { getCookie } from '../utils/Cookies';
import Url, { getWebsiteUrl } from '../utils/Url';

interface UserProfile {
    email: string;
    name: string | null;
    subscription: {
        type: {
            name: string;
            aiAccess: boolean;
            maxAmountOfBookletsAllowedToGeneratePerMonth: number;
        };
        expirationDate?: string;
    };
    numberOfExamPapersGenerated: number;
}

const UserProfile = () => {
    const [profile, setProfile] = useState<UserProfile | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { isDarkMode } = useTheme();
    const navigate = useNavigate();
    const jwtToken = getCookie('jwt');

    // Animation for the profile section
    const fadeIn = useSpring({
        from: { opacity: 0, transform: 'translateY(20px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
        config: { tension: 280, friction: 20 }
    });

    // Staggered animations for cards
    const cardAnimations = [
        useSpring({
            from: { opacity: 0, transform: 'scale(0.9)' },
            to: { opacity: 1, transform: 'scale(1)' },
            delay: 200,
            config: { tension: 280, friction: 20 }
        }),
        useSpring({
            from: { opacity: 0, transform: 'scale(0.9)' },
            to: { opacity: 1, transform: 'scale(1)' },
            delay: 400,
            config: { tension: 280, friction: 20 }
        })
    ];

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await fetch(UserInfoAPIUrls.INFO(jwtToken as string), {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${jwtToken}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user profile');
                }

                const data = await response.json();
                const userProfile: UserProfile = {
                    email: data.oauthJWt.email,
                    name: data.name,
                    subscription: {
                        type: {
                            name: data.subscriptionPlan.name,
                            aiAccess: data.subscriptionPlan.aiAccess,
                            maxAmountOfBookletsAllowedToGeneratePerMonth: data.subscriptionPlan.maxAmountOfBookletsAllowedToGeneratePerMonth
                        },
                        expirationDate: data.subscriptionPlan.name !== 'Free' ? data.subscriptionExpirationDate : undefined
                    },
                    numberOfExamPapersGenerated: data.numberOfExamPapersGenerated
                };
                setProfile(userProfile);
            } catch (err) {
                setError('Failed to load profile data');
            } finally {
                setLoading(false);
            }
        };

        fetchUserProfile();
    }, [jwtToken]);

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen" style={getCurrentStyles(isDarkMode)}>
                <div className="w-12 h-12 border-4 border-green-600 rounded-full animate-spin border-t-transparent"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-screen" style={getCurrentStyles(isDarkMode)}>
                <div className="p-6 bg-red-100 rounded-lg dark:bg-red-900">
                    <p className="text-center text-red-600 dark:text-red-200">{error}</p>
                </div>
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Revise Wizard - Profile</title>
                <meta name="description" content="Your Revise Wizard profile and statistics" />
                <link rel="canonical" href={getWebsiteUrl() + Url.USER_PAGE} />
            </Helmet>

            <div className="flex flex-col items-center flex-grow px-4 py-6 sm:px-6 md:px-8" style={getCurrentStyles(isDarkMode)}>
                <div className="w-full max-w-4xl space-y-8">
                    {/* Profile Header */}
                    <animated.div style={fadeIn}
                                  className="overflow-hidden bg-white shadow-lg dark:bg-gray-800 rounded-xl backdrop-blur-sm bg-opacity-90 dark:bg-opacity-90">
                        <div className="flex flex-col items-center p-8 space-y-4 sm:flex-row sm:space-y-0 sm:space-x-6">
                            <div className="relative">
                                <UserCircle className="w-24 h-24 text-green-600"/>
                                <div className="absolute bottom-0 right-0 w-6 h-6 bg-green-500 border-4 border-white rounded-full dark:border-gray-800"></div>
                            </div>
                            <div className="text-center sm:text-left">
                                <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
                                    {profile?.name || profile?.email}
                                </h1>
                                {profile?.name && (
                                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                                        {profile.email}
                                    </p>
                                )}
                                <div className="w-full my-4 border-b border-gray-300 dark:border-gray-700"></div>
                                <blockquote className="text-lg italic text-gray-600 dark:text-gray-300">
                                    <span className="text-2xl font-semibold text-gray-900 dark:text-white">"</span>
                                    Success is the sum of small efforts, repeated day in and day out.
                                    <span className="text-2xl font-semibold text-gray-900 dark:text-white">"</span>
                                </blockquote>
                                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">– Robert Collier</p>
                            </div>
                        </div>
                    </animated.div>

                    {/* Stats Grid */}
                    <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                        {/* Subscription Card */}
                        <animated.div style={cardAnimations[0]}
                                      className="p-6 transition-shadow duration-300 bg-white shadow-lg group dark:bg-gray-800 rounded-xl hover:shadow-xl backdrop-blur-sm bg-opacity-90 dark:bg-opacity-90">
                            <div className="flex items-center space-x-4">
                                <div
                                    className="p-3 transition-transform duration-300 bg-yellow-100 rounded-lg dark:bg-yellow-900 group-hover:scale-110">
                                    <Crown className="w-8 h-8 text-yellow-600 dark:text-yellow-400"/>
                                </div>
                                <div>
                                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                        {profile?.subscription.type?.name} Plan
                                    </h3>
                                    {profile?.subscription.expirationDate && (
                                        <p className="text-sm text-gray-600 dark:text-gray-300">
                                            Valid
                                            until {new Date(profile.subscription.expirationDate).toLocaleDateString()}
                                        </p>
                                    )}
                                    <p className="text-sm text-gray-500 dark:text-gray-400">
                                        AI Access: {profile?.subscription.type?.aiAccess ? 'Yes' : 'No'}
                                    </p>
                                </div>
                            </div>
                        </animated.div>

                        {/* Papers Generated Card */}
                        <animated.div style={cardAnimations[1]}
                                      className="p-6 transition-shadow duration-300 bg-white shadow-lg group dark:bg-gray-800 rounded-xl hover:shadow-xl backdrop-blur-sm bg-opacity-90 dark:bg-opacity-90">
                            <div className="flex items-center space-x-4">
                                <div
                                    className="p-3 transition-transform duration-300 bg-blue-100 rounded-lg dark:bg-blue-900 group-hover:scale-110">
                                    <FileText className="w-8 h-8 text-blue-600 dark:text-blue-400"/>
                                </div>
                                <div>
                                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                        Papers Generated
                                    </h3>
                                    <p className="text-3xl font-bold text-blue-600 dark:text-blue-400">
                                        {profile?.numberOfExamPapersGenerated}
                                    </p>
                                </div>
                            </div>
                        </animated.div>
                    </div>

                    {/* Action Buttons */}
                    <div className="flex flex-col items-center justify-center gap-4 mt-8 sm:flex-row">
                        <button
                            onClick={() => navigate(Url.MATH)}
                            className="flex items-center justify-center w-full px-8 py-4 space-x-2 font-semibold text-white transition duration-300 transform shadow-lg sm:w-auto bg-gradient-to-r from-green-600 to-green-500 rounded-xl hover:from-green-700 hover:to-green-600 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 hover:shadow-xl"
                        >
                            <FileText className="w-5 h-5"/>
                            <span>Generate New Paper</span>
                        </button>

                        <button
                            onClick={() => navigate(Url.SETTINGS_PAGE)}
                            className="flex items-center justify-center w-full px-8 py-4 space-x-2 font-semibold text-gray-700 transition duration-300 transform bg-gray-100 shadow-lg sm:w-auto dark:bg-gray-700 dark:text-gray-300 rounded-xl hover:bg-gray-200 dark:hover:bg-gray-600 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 hover:shadow-xl"
                        >
                            <Settings className="w-5 h-5"/>
                            <span>Account Settings</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserProfile;