import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { ReactTyped } from "react-typed";
import { useTheme } from "../../components/ThemeContext";
import { InfoAPIUrls } from "../../utils/APIUrls";
import { getCurrentButtonStyles, getCurrentStyles } from "../../utils/Common";
import Url, { getWebsiteUrl } from "../../utils/Url";

const MathPage: React.FC = () => {
    const {isDarkMode} = useTheme();
    const [topics, setTopics] = useState<{ value: string; label: string }[]>([]);
    const [loadingTopics, setLoadingTopics] = useState<boolean>(false);

    useEffect(() => {
        const fetchTopics = async () => {
            setLoadingTopics(true);
            let level = ['aslevel', 'alevel'][Math.floor(Math.random() * 2)];
            try {
                const response = await axios.get(InfoAPIUrls.TOPIC_LIST, {params: {level}});
                const topicsData = response.data.topics.map((topic: string) => ({value: topic, label: topic}));
                setTopics(topicsData);
            } catch (error: any) {
                console.error('Error fetching topics:', error);
                setTopics([]);
            } finally {
                setLoadingTopics(false);
            }
        };

        fetchTopics().then(r => r);
    }, []);

    return <>
        <Helmet>
            <title>Revise Wizard - Math</title>
            <meta name="description"
                  content="Revise Wizard's math section, where you can access past papers, topical questions, and notes to help you prepare for your exams."/>
            <link rel="canonical" href={getWebsiteUrl() + Url.MATH}/>
        </Helmet>

        <div className="flex flex-col items-center justify-center flex-grow p-8"
             style={getCurrentStyles(isDarkMode)}>
            <div className="w-full max-w-7xl">
                <div className="grid grid-cols-1 gap-12 md:grid-cols-3">
                    {/* Paper Retrieval Section */}
                    <div className="p-6 rounded-lg shadow-lg"
                         style={{
                             backgroundColor: getCurrentStyles(isDarkMode).backgroundColor,
                             color: getCurrentStyles(isDarkMode).color
                         }}>
                        <h2 className="mb-4 text-2xl font-bold">Paper Retrieval</h2>
                        <p className="mb-4">
                            Find past papers for your math exams. Access a wide range of papers to practice and prepare
                            for your tests.
                        </p>
                        <Link to={Url.MATH_EXAM_PAPER_RETRIEVAL}>
                            <button className="px-4 py-2 transition duration-300 rounded hover:bg-opacity-75"
                                    style={getCurrentButtonStyles(isDarkMode)}>
                                Go to Paper Retrieval
                            </button>
                        </Link>
                    </div>

                    {/* Topical Questions and Builder Section */}
                    <div className="p-6 rounded-lg shadow-lg"
                         style={{
                             backgroundColor: getCurrentStyles(isDarkMode).backgroundColor,
                             color: getCurrentStyles(isDarkMode).color
                         }}>
                        <h2 className="mb-4 text-2xl font-bold">Topical Paper Generator</h2>
                        <p className="mb-4">
                            Build and solve topical questions to enhance your understanding of different math concepts.
                            Customize your practice to focus on areas where you need improvement.
                        </p>
                        <Link to={Url.MATH_TOPIC_PAPER_GENERATOR}>
                            <button className="px-4 py-2 transition duration-300 rounded hover:bg-opacity-75"
                                    style={getCurrentButtonStyles(isDarkMode)}>
                                Go to Topical Selector
                            </button>
                        </Link>
                    </div>

                    <div className="p-6 rounded-lg shadow-lg"
                         style={{
                             backgroundColor: getCurrentStyles(isDarkMode).backgroundColor,
                             color: getCurrentStyles(isDarkMode).color
                         }}>
                        <h2 className="mb-4 text-2xl font-bold">Exam Paper Builder</h2>
                        <p className="mb-4">
                            Create your own exam papers by selecting questions from our question bank. Choose questions
                            based on topics you want to practice and generate a custom exam paper.
                        </p>
                        <Link to={Url.MATH_EXAM_PAPER_BUILDER}>
                            <button className="px-4 py-2 transition duration-300 rounded hover:bg-opacity-75"
                                    style={getCurrentButtonStyles(isDarkMode)}>
                                Go to Exam Paper Builder
                            </button>
                        </Link>
                    </div>


                    {/* Topical Notes Section */}
                    <div className="p-6 rounded-lg shadow-lg"
                         style={{
                             backgroundColor: getCurrentStyles(isDarkMode).backgroundColor,
                             color: getCurrentStyles(isDarkMode).color
                         }}>
                        <h2 className="mb-4 text-2xl font-bold">Topical Notes</h2>
                        <p className="mb-4">
                            Access comprehensive notes on various math topics. These notes will help you understand and
                            revise key concepts efficiently.
                        </p>
                        <Link to="">
                            <button className="px-4 py-2 transition duration-300 rounded hover:bg-opacity-75"
                                    style={getCurrentButtonStyles(isDarkMode)} disabled>
                                Coming Soon
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="mt-12 text-2xl font-bold">
                    Math topics available:
                    {loadingTopics ? (
                        <span>Loading topics...</span>
                    ) : (
                        <ReactTyped
                            strings={topics.map((topic) => topic.label)}
                            typeSpeed={40}
                            backSpeed={50}
                            loop
                            className="ml-2"
                            style={{color: '#86C232'}}
                        />
                    )}
                </div>
            </div>
        </div>
    </>;
};

export default MathPage;
