let baseURL = `${process.env.REACT_APP_API_URL}/api`;

let authURL = `${baseURL}/auth`;
let userURL = `${baseURL}/user`;
let verificationURL = `${baseURL}/verification`;
let examURL = `${baseURL}/exam`;
let pdfURL = `${baseURL}/pdf`;
let uploadURL = `${baseURL}/upload`;
let infoURL = `${baseURL}/info`;

export const AuthAPIUrls = {
    SIGNUP: `${authURL}/signup`,
    LOGIN: `${authURL}/login`,
    VERIFY: `${authURL}/verify`,
    REQUEST_PASSWORD_RESET: `${authURL}/request-password-reset`,
    FULFILL_PASSWORD_RESET: `${authURL}/fulfill-password-reset`,
    CHANGE_PASSWORD : `${authURL}/change-password`,
    VERIFY_PASSWORD_REST_TOKEN: `${authURL}/verify-password-reset-token`,
    IS_PASSWORD_NULL : `${authURL}/is-password-null`,
    GOOGLE : `${authURL}/google`,

} as const;

export const ExamPaperBuildAPIUrls = {
    BUILD_EXAM_PAPER: `${examURL}/build-exam-paper`,
    QUESTIONS_FOR_TOPICS: `${examURL}/questions-for-topics`,
    TOPICS_FOR_LEVEL: `${examURL}/topics-for-level`,
} as const;

export const PDFAPIUrls = {
    TOPIC: `${pdfURL}/topic`,
    TOPICS: `${pdfURL}/topics`,
    PAST_PAPER: `${pdfURL}/past-paper`,
    EXAM_BOARDS: `${pdfURL}/exam-boards`,
    PAST_PAPER_INFO: `${pdfURL}/past-paper-info`,
} as const;

export const StatusAPIUrls = {
    STATUS: `${baseURL}/status`,
}

export const UploadAPIUrls = {
    QUESTIONS: `${uploadURL}/questions`,
}

export const UserInfoAPIUrls = {
    INFO: (jwt : string) => `${userURL}/${jwt}/info`,
    SUBSCRIPTION: (jwt: string, email: string) => `${userURL}/${jwt}/subscription/${email}`,
    USER_ROLE: (jwt: string) => `${userURL}/${jwt}/user-role`,
    MARK_PER_QUESTION: (jwt: string) => `${userURL}/${jwt}/mark_per_question`,
    NAME: (jwt: string) => `${userURL}/${jwt}/name`,
} as const;

export const VerificationAPIUrls = {
    VERIFY_HCAPTCHA: `${verificationURL}/verify-hcaptcha`,
} as const;

export const InfoAPIUrls = {
    TOPIC_LIST: `${infoURL}/topic-list`,
    ACADEMIC_LEVELS: `${infoURL}/academic-levels`,
    EXAM_BOARDS: `${infoURL}/exam-boards`,
} as const;

