import React from 'react';
import {Route, Routes} from "react-router";
import Home from "./pages/Home";
import Login from "./pages/oauth/Login";
import SignUp from "./pages/oauth/SignUp";
import Verification from "./pages/oauth/Verification";
import NotFound from "./pages/NotFound";
import ResetPasswordRequest from "./pages/oauth/ResetPasswordRequest";
import ResetPassword from "./pages/oauth/ResetPassword";
import useAuth from "./utils/useAuth";
import UserAgreement from "./pages/terms/UserAgreement";
import TermsOfService from "./pages/terms/TermsOfService";
import PrivacyPolicy from "./pages/terms/PrivacyPolicy";
import Footer from "./components/Footer";
import GoogleLoading from "./components/GoogleLoading";
import LoggingOut from "./pages/oauth/LoggingOut";
import {ThemeProvider, useTheme} from './components/ThemeContext';
import MathPage from "./pages/subjects/MathPage";
import ExamPaperRetriever from "./pages/math/ExamPaperRetriever";
import TopicPaperGenerator from "./pages/math/TopicPaperGenerator";
import Url from "./utils/Url";
import QuestionUploader from "./pages/math/QuestionUploader";
import ExamPaperBuilder from "./pages/math/ExamPaperBuilder";
import NavBar from "./components/NavBar";
import UserPage from "./pages/UserPage";
import MaintenanceWrapper from './pages/MaintenancePage';
import SettingsPage from "./pages/oauth/SettingsPage";

declare global {
    interface Window {
        google: any;
    }
}

function App() {
    const {jwt, role} = useAuth(null);
    const isAdmin = role === 'ADMIN';

    return (
        <ThemeProvider>
            <MaintenanceWrapper isAdmin={isAdmin}>

                <div className="app-container flex flex-col min-h-screen">
                    <NavBar/>
                    <div className="content flex-grow">
                        <Routes>
                            {jwt ? (
                                <>
                                    <Route path={Url.HOME} element={<Home/>}/>
                                    <Route path={Url.USER_PAGE} element={<UserPage/>}/>
                                    <Route path={Url.SETTINGS_PAGE} element={<SettingsPage/>}/>
                                    <Route path={Url.MATH} element={<MathPage/>}/>
                                    <Route path={Url.MATH_EXAM_PAPER_RETRIEVAL} element={<ExamPaperRetriever/>}/>
                                    <Route path={Url.MATH_TOPIC_PAPER_GENERATOR} element={<TopicPaperGenerator/>}/>
                                    <Route path={Url.MATH_EXAM_PAPER_BUILDER} element={<ExamPaperBuilder/>}/>
                                    {isAdmin &&
                                        <Route path={Url.MATH_EXAM_QUESTION_UPLOADER} element={<QuestionUploader/>}/>}
                                    <Route path={Url.USER_AGREEMENT} element={<UserAgreement/>}/>
                                    <Route path={Url.TERMS_OF_SERVICE} element={<TermsOfService/>}/>
                                    <Route path={Url.PRIVACY_POLICY} element={<PrivacyPolicy/>}/>
                                    <Route path={Url.LOGGING_OUT} element={<LoggingOut/>}/>
                                </>
                            ) : (
                                <>
                                    <Route path={Url.HOME} element={<Home/>}/>
                                    <Route path={Url.GOOGLE_LOADING} element={<GoogleLoading/>}/>
                                    <Route path={Url.LOGIN} element={<Login/>}/>
                                    <Route path={Url.SIGN_UP} element={<SignUp/>}/>
                                    <Route path={Url.VERIFICATION} element={<Verification/>}/>
                                    <Route path={Url.RESET_PASSWORD_REQUEST} element={<ResetPasswordRequest/>}/>
                                    <Route path={Url.RESET_PASSWORD} element={<ResetPassword/>}/>
                                    <Route path={Url.USER_AGREEMENT} element={<UserAgreement/>}/>
                                    <Route path={Url.TERMS_OF_SERVICE} element={<TermsOfService/>}/>
                                    <Route path={Url.PRIVACY_POLICY} element={<PrivacyPolicy/>}/>
                                    <Route path={Url.LOGGING_OUT} element={<LoggingOut/>}/>
                                </>
                            )}
                            <Route path="*" element={<NotFound/>}/>
                        </Routes>
                    </div>
                    <Footer/>
                </div>
            </MaintenanceWrapper>
        </ThemeProvider>
    );
}

export default App;
