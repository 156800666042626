import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTheme} from "../components/ThemeContext";
import {Helmet} from "react-helmet-async";
import Url from "../utils/Url";
import {getWebsiteUrl} from "../utils/Url";
import {getCurrentButtonStyles, getCurrentStyles} from "../utils/Common";

const NotFound = () => {
    const { isDarkMode } = useTheme();
    const [showEasterEgg, setShowEasterEgg] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowEasterEgg(true);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <Helmet>
                <title>Revise Wizard - 404</title>
                <meta name="description" content="404 - Page Not Found, the page you are looking for does not exist." />
                <link rel="canonical" href={getWebsiteUrl() + Url.NOT_FOUND} />
            </Helmet>
            <div className="flex flex-col items-center justify-center flex-grow p-8 pb-24" style={getCurrentStyles(isDarkMode)}>
                <h1 className="text-4xl font-bold mb-4">404 - Page Not Found</h1>
                <p className="text-lg mb-6">Oops! The page you are looking for does not exist.</p>
                <Link to="/">
                    <button
                        className="px-4 py-2 rounded hover:bg-opacity-75 transition duration-300 transform hover:scale-110"
                        style={getCurrentButtonStyles(isDarkMode)}
                    >
                        Go to Home
                    </button>
                </Link>

                {showEasterEgg && (
                    <div className="mt-6 text-center">
                        <p className="text-lg font-semibold">Still lost? Here's a map! 🗺️</p>
                        <p className="text-sm mt-2">Or maybe try pressing "Go to Home" before we send out a search party!</p>
                        <div className="mt-4 animate-bounce">
                            <span role="img" aria-label="compass">🧭</span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default NotFound;
