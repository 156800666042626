import React, { useState, useEffect, useCallback } from 'react';
import Select, { MultiValue } from 'react-select';
import { FaSpinner } from "@react-icons/all-files/fa/FaSpinner";
import axios from 'axios';
import { useNavigate } from 'react-router';
import { getCookie, removeCookie } from '../../utils/Cookies';
import { getWebsiteUrl } from "../../utils/Url";
import { useTheme } from "../../components/ThemeContext";
import { Helmet } from 'react-helmet-async';
import { getButtonClass, getCurrentButtonStyles, getCurrentStyles, getCustomStyles } from "../../utils/Common";
import { AcademicLevel } from "../../utils/AcademicLevel";
import Url from "../../utils/Url";
import {ExamPaperBuildAPIUrls} from "../../utils/APIUrls";
import { HTTPErrorCode } from '../../utils/HTTPCode';

interface TopicOption {
    value: string;
    label: string;
}

interface Question {
    questionIds: string[];
    questionName: string[];
    previewUrls: string[];
}

const levelLabels: Record<AcademicLevel, string> = {
    [AcademicLevel.GCSE]: 'GCSE',
    [AcademicLevel.AS_LEVEL]: 'AS Level',
    [AcademicLevel.A_LEVEL]: 'A Level'
};

const ExamPaperBuilder: React.FC = () => {
    const { isDarkMode } = useTheme();
    const navigate = useNavigate();

    const defaultLevel: AcademicLevel = AcademicLevel.A_LEVEL;

    const [selectedLevel, setSelectedLevel] = useState<AcademicLevel>(() => {
        const savedLevel = localStorage.getItem('selectedLevel');
        return savedLevel ? JSON.parse(savedLevel) : defaultLevel;
    });
    const [topics, setTopics] = useState<TopicOption[]>([]);
    const [selectedTopics, setSelectedTopics] = useState<MultiValue<TopicOption>>(() => {
        const savedTopics = localStorage.getItem('selectedTopics');
        return savedTopics ? JSON.parse(savedTopics) : [];
    });
    const [questions, setQuestions] = useState<Question | null>(() => {
        const savedQuestions = localStorage.getItem('questions');
        return savedQuestions ? JSON.parse(savedQuestions) : null;
    });
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number | null>(() => {
        const savedIndex = localStorage.getItem('selectedQuestionIndex');
        return savedIndex ? JSON.parse(savedIndex) : null;
    });
    const [basket, setBasket] = useState<string[]>(() => {
        const savedBasket = localStorage.getItem('basket');
        return savedBasket ? JSON.parse(savedBasket) : [];
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingTopics, setLoadingTopics] = useState<boolean>(false);
    const [isFetchingQuestions, setIsFetchingQuestions] = useState<boolean>(false);
    const [isTopicSelectionDisabled, setIsTopicSelectionDisabled] = useState<boolean>(() => {
        const savedDisabled = localStorage.getItem('isTopicSelectionDisabled');
        return savedDisabled ? JSON.parse(savedDisabled) : false;
    });
    const [isContinueDisabled, setIsContinueDisabled] = useState<boolean>(() => {
        const savedDisabled = localStorage.getItem('isContinueDisabled');
        return savedDisabled ? JSON.parse(savedDisabled) : false;
    });
    const [isResetDisabled, setIsResetDisabled] = useState<boolean>(() => {
        const savedDisabled = localStorage.getItem('isResetDisabled');
        return savedDisabled ? JSON.parse(savedDisabled) : true;
    });


    const handleError = useCallback((error: any) => {
        if (error.response) {
            switch (error.response.status) {
                case HTTPErrorCode.UNAUTHORIZED:
                case HTTPErrorCode.FORBIDDEN:
                    alert('Authentication error. Please log in again.');
                    removeCookie('jwt');
                    navigate('/');
                    break;
                case HTTPErrorCode.NOT_FOUND:
                    alert('The requested resource was not found.');
                    break;
                case HTTPErrorCode.TOO_MANY_REQUESTS:
                    alert('Too many requests. Please try again later.');
                    break;
                case HTTPErrorCode.INTERNAL_SERVER_ERROR:
                    alert('Server error. Please try again later.');
                    break;
                default:
                    alert('An unexpected error occurred. Please try again.');
            }
        } else if (error.request) {
            alert('Network error. Please check your connection.');
        } else {
            alert('An unexpected error occurred');
        }
    }, [navigate]);

    const fetchTopics = useCallback(async (level: string) => {
        setLoadingTopics(true);
        try {
            const params = new URLSearchParams();
            params.append('level', level);

            const response = await axios.get(ExamPaperBuildAPIUrls.TOPICS_FOR_LEVEL, { params });
            const fetchedTopics = Object.keys(response.data).map(topic => ({ value: topic, label: topic }));
            setTopics(fetchedTopics);
            localStorage.setItem('topics', JSON.stringify(fetchedTopics));
        } catch (error) {
            console.error('Error fetching topics:', error);
            handleError(error);
        } finally {
            setLoadingTopics(false);
        }
    }, [handleError]);

    const fetchQuestions = useCallback(async () => {
        setLoading(true);
        try {
            const topicsAndLevels = selectedTopics.reduce((acc: Record<string, string>, topic) => {
                acc[topic.value] = selectedLevel.toLowerCase();
                return acc;
            }, {});

            const response = await axios.post(
               ExamPaperBuildAPIUrls.QUESTIONS_FOR_TOPICS,
                topicsAndLevels,
                {
                    params: { jwtToken: getCookie('jwt') || '' }
                }
            );

            let { questionName, questionIds, previewUrls } = response.data;

            const sortedQuestions = questionName
                .map((name: string, index: number) => ({
                    name,
                    id: questionIds[index],
                    url: previewUrls[index]
                }))
                .sort((a: { name: { match: (arg0: RegExp) => any[]; }; }, b: { name: { match: (arg0: RegExp) => any[]; }; }) => {
                    const aNumber = parseInt(a.name.match(/\d+/)?.[0] || '0', 10);
                    const bNumber = parseInt(b.name.match(/\d+/)?.[0] || '0', 10);
                    return aNumber - bNumber;
                });

            questionName = sortedQuestions.map((q: { name: any; }) => q.name);
            questionIds = sortedQuestions.map((q: { id: any; }) => q.id);
            previewUrls = sortedQuestions.map((q: { url: any; }) => q.url);

            const fetchedQuestions: Question = {
                questionIds,
                questionName,
                previewUrls
            };

            setQuestions(fetchedQuestions);
            localStorage.setItem('questions', JSON.stringify(fetchedQuestions));
        } catch (error) {
            console.error('Error fetching questions:', error);
            handleError(error);
        } finally {
            setLoading(false);
            setIsFetchingQuestions(false);
        }
    }, [selectedTopics, selectedLevel, handleError]);


    useEffect(() => {
        if (selectedLevel) {
            fetchTopics(selectedLevel).then(r => r);
        }
    }, [selectedLevel, fetchTopics]);

    useEffect(() => {
        if (selectedTopics.length > 0 && isFetchingQuestions) {
            fetchQuestions().then(r => r);
        }
    }, [selectedTopics, isFetchingQuestions, fetchQuestions]);

    useEffect(() => {
        localStorage.setItem('selectedLevel', JSON.stringify(selectedLevel));
        localStorage.setItem('selectedTopics', JSON.stringify(selectedTopics));
        localStorage.setItem('selectedQuestionIndex', JSON.stringify(selectedQuestionIndex));
        localStorage.setItem('basket', JSON.stringify(basket));
        localStorage.setItem('isTopicSelectionDisabled', JSON.stringify(isTopicSelectionDisabled));
        localStorage.setItem('isContinueDisabled', JSON.stringify(isContinueDisabled));
        localStorage.setItem('isResetDisabled', JSON.stringify(isResetDisabled));
    }, [selectedLevel, selectedTopics, selectedQuestionIndex, basket, isTopicSelectionDisabled, isContinueDisabled, isResetDisabled]);

    const handleLevelChange = (level: 'gcse' | 'aslevel' | 'alevel') => {
        let academicLevel: AcademicLevel;
        switch (level) {
            case 'gcse':
                return;
            case 'aslevel':
                academicLevel = AcademicLevel.AS_LEVEL;
                break;
            case 'alevel':
                academicLevel = AcademicLevel.A_LEVEL;
                break;
        }
        setSelectedLevel(academicLevel);
        setSelectedTopics([]);
        setQuestions(null);
        setSelectedQuestionIndex(null);
        setBasket([]);
        setIsTopicSelectionDisabled(false);
        setIsContinueDisabled(false);
        setIsResetDisabled(true);
    };

    const handleTopicChange = (selectedOptions: MultiValue<TopicOption>) => {
        setSelectedTopics(selectedOptions);
        setIsResetDisabled(false);
    };

    const handleQuestionSelect = (index: number) => {
        setSelectedQuestionIndex(index);
    };

    const handleAddToBasket = useCallback(() => {
        if (selectedQuestionIndex !== null && questions) {
            const questionId = questions.questionIds[selectedQuestionIndex];
            if (!basket.includes(questionId)) {
                setBasket(prevBasket => [...prevBasket, questionId]);
            }
        }
    }, [selectedQuestionIndex, questions, basket]);

    useEffect(() => {
        console.log('Basket updated:', basket);
    }, [basket]);

    const handleRemoveFromBasket = (questionId: string) => {
        setBasket(basket.filter(id => id !== questionId));
    };

    const handleBuildExamPaper = async () => {
        setLoading(true);
        try {
            const response = await axios.post(ExamPaperBuildAPIUrls.BUILD_EXAM_PAPER, basket, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${getCookie('jwt')}`
                },
                responseType: 'arraybuffer'
            });

            const blob = new Blob([response.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'exam_paper.pdf';
            link.click();
        } catch (error) {
            console.error('Error building exam paper:', error);
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleContinue = () => {
        if (selectedTopics.length > 0) {
            setIsFetchingQuestions(true);
            setIsTopicSelectionDisabled(true);
            setIsContinueDisabled(true);
            setIsResetDisabled(false);
        }
    };

    const handleClear = () => {
        setSelectedTopics([]);
        setQuestions(null);
        setSelectedQuestionIndex(null);
        setBasket([]);
        setIsTopicSelectionDisabled(false);
        setIsContinueDisabled(false);
        setIsResetDisabled(true);
    };

    const handleReset = () => {
        handleClear();
        setIsResetDisabled(true);
        localStorage.clear();
    };


    const customStyles = getCustomStyles(isDarkMode);

    return (
        <>
            <Helmet>
                <title>Revise Wizard - Exam Paper Builder</title>
                <meta name="description" content="Build your custom exam paper with Revise Wizard's Exam Paper Builder." />
                <link rel="canonical" href={getWebsiteUrl() + Url.MATH_EXAM_PAPER_BUILDER}/>
            </Helmet>
            <div className="flex flex-col items-center justify-center flex-grow py-6 md:py-8" style={getCurrentStyles(isDarkMode)}>
                <h1 className="text-4xl font-bold mb-8">Exam Paper Builder</h1>

                <div className="w-full max-w-3xl">
                    <div className="mb-6">
                        <h2 className="text-2xl mb-2">Select Education Level:</h2>
                        <div className="flex space-x-4">
                            {Object.keys(levelLabels).map((level) => (
                                level !== AcademicLevel.GCSE && (
                                    <button
                                        key={level}
                                        onClick={() => handleLevelChange(level as 'gcse' | 'aslevel' | 'alevel')}
                                        className={getButtonClass(level, selectedLevel, isDarkMode)}
                                    >
                                        {levelLabels[level as AcademicLevel]}
                                    </button>
                                )
                            ))}
                        </div>
                    </div>

                    {selectedLevel && (
                        <div className="mb-6">
                            <h2 className="text-2xl mb-2">Select Topics:</h2>
                            {loadingTopics ? (
                                <div className="flex justify-center items-center">
                                    <FaSpinner className="animate-spin" />
                                </div>
                            ) : (
                                <Select
                                    isMulti
                                    options={topics}
                                    value={selectedTopics}
                                    onChange={handleTopicChange}
                                    styles={customStyles}
                                    isDisabled={isTopicSelectionDisabled}
                                />
                            )}
                        </div>
                    )}

                    <div className="flex space-x-4 mt-4">
                        <button
                            onClick={handleContinue}
                            className={`p-2 rounded ${loadingTopics || isContinueDisabled ? 'bg-gray-500' : 'bg-blue-500'} text-white`}
                            disabled={loadingTopics || isContinueDisabled || selectedTopics.length === 0}
                        >
                            {isFetchingQuestions ? (
                                <FaSpinner className="animate-spin" />
                            ) : (
                                'Continue'
                            )}
                        </button>
                        <button
                            onClick={handleReset}
                            className={`p-2 rounded ${isResetDisabled ? 'bg-gray-400' : 'bg-gray-400'} text-white`}
                            disabled={isResetDisabled}
                        >
                            Reset
                        </button>
                    </div>

                    {questions && questions.questionIds.length > 0 && (
                        <div className="flex space-x-4 mt-8">
                            <div className="w-1/2">
                                <h2 className="text-2xl mb-2">Questions:</h2>
                                <ul className="max-h-96 overflow-y-auto">
                                    {questions.questionIds.map((id, index) => (
                                        <li
                                            key={id}
                                            className={`p-2 cursor-pointer ${selectedQuestionIndex === index ? 'bg-blue-500 text-white' : ''}`}
                                            onClick={() => handleQuestionSelect(index)}
                                        >
                                            {questions.questionName[index]}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="w-1/2">
                                <h2 className="text-2xl mb-2">Preview:</h2>
                                {selectedQuestionIndex !== null && (
                                    <div>
                                        <img src={questions.previewUrls[selectedQuestionIndex]} alt="Question preview" className="w-full h-auto mb-4" />
                                        <button
                                            onClick={handleAddToBasket}
                                            className={`p-2 rounded ${basket.includes(questions.questionIds[selectedQuestionIndex]) ? 'bg-gray-400' : 'bg-blue-500'} text-white`}
                                        >
                                            {basket.includes(questions.questionIds[selectedQuestionIndex]) ? 'Added to Paper' : 'Add to Paper'}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {basket.length > 0 && (
                        <div className="mt-8">
                            <h2 className="text-2xl mb-2">Paper:</h2>
                            <ul className="mb-4">
                                {basket.map((id) => {
                                    const index = questions?.questionIds.indexOf(id) ?? -1;
                                    if (index >= 0) {
                                        return (
                                            <li key={id} className="flex justify-between items-center p-2">
                                                <span>{questions?.questionName[index] ?? 'Unnamed Question'}</span>
                                                <button
                                                    onClick={() => handleRemoveFromBasket(id)}
                                                    className="ml-4 p-2 bg-red-500 text-white rounded"
                                                >
                                                    Remove
                                                </button>
                                            </li>
                                        );
                                    }
                                    return null; // Ensure a value is returned in all cases
                                })}
                            </ul>
                            <button
                                onClick={handleBuildExamPaper}
                                className={`p-2 rounded ${loading ? 'bg-gray-500' : 'bg-green-500'} text-white`}
                                disabled={loading}
                            >
                                {loading ? (
                                    <FaSpinner className="animate-spin" />
                                ) : (
                                    'Build Exam Paper'
                                )}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ExamPaperBuilder;